<template>
  <div>
    <!-- recherche -->
    <div class="position-relative">
      <b-card no-body class="invoice-preview-card">
        <b-card class="m-0 mb-1">
          <b-row>
            <b-col lg="12">
              <!-- recherche par periode -->
              <div class="d-flex flex-row-reverse nopadding">
                <b-form-group
                  class="col pr-0 pl-0"
                  label-cols-xl="3"
                  label="Rechercher par période d'enregistrement"
                >
                  <b-input-group>
                    <DateRangeWidget
                      v-model="filterBy.periode"
                      :range="filterBy.periode"
                      placeholder="Filtrer par date"
                      @keyup.enter="search()"
                    />
                  </b-input-group>
                </b-form-group>
              </div>
              <!-- nom etablissement  -->
              <div class="d-flex flex-row-reverse nopadding">
                <b-form-group
                  class="col pr-0 pl-0"
                  label-cols-xl="3"
                  input-cols-xl="9"
                  label="Rechercher par Établissement associé"
                >
                  <b-input-group>
                    <v-select
                      v-model="filterBy.etablissement"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="nomEtablissement"
                      :reduce="(option) => option._id"
                      :options="tableDataEtablissement"
                      placeholder="Choisissez un établissement. Exemple: Home Bénin"
                      @keyup.enter="search()"
                    />
                  </b-input-group>
                </b-form-group>
              </div>
              <!-- role  -->
              <div class="d-flex flex-row-reverse nopadding">
                <b-form-group
                  class="col pr-0 pl-0"
                  label-cols-xl="3"
                  label="Rechercher par rôle"
                >
                  <b-input-group class="w-100">
                    <v-select
                      v-model="filterBy.role"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="nom"
                      :reduce="(option) => option._id"
                      :options="roles"
                      placeholder="Choisissez un rôle. Exemple: Agent MTCA"
                      @keyup.enter="search()"
                    />
                  </b-input-group>
                </b-form-group>
              </div>
              <!-- nom-->
              <div class="d-flex flex-row-reverse nopadding">
                <b-form-group
                  class="col pr-0 pl-0"
                  label-cols-xl="3"
                  label="Rechercher par le nom"
                >
                  <b-input-group>
                    <b-form-input
                      v-model="filterBy.lastname"
                      type="text"
                      class="form-control"
                      placeholder="Entrer l'email de l'utilisateur. Exemple: ATTOU"
                      @keyup.enter="search()"
                    />
                    <b-input-group-append
                      is-text
                      @click="
                        () => {
                          filterBy.lastname = null;
                        }
                      "
                    >
                      <feather-icon
                        v-b-tooltip.hover.bottom="'Vider le champs!'"
                        icon="XCircleIcon"
                        class="cursor-pointer"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </div>
              <!-- prenom -->
              <div class="d-flex flex-row-reverse nopadding">
                <b-form-group
                  class="col pr-0 pl-0"
                  label-cols-xl="3"
                  label="Rechercher par le prénom"
                >
                  <b-input-group>
                    <b-form-input
                      v-model="filterBy.firstname"
                      type="text"
                      class="form-control"
                      placeholder="Entrer le prénom de l'utilisateur. Exemple: Luc"
                      @keyup.enter="search()"
                    />
                    <b-input-group-append
                      is-text
                      @click="
                        () => {
                          filterBy.firstname = null;
                        }
                      "
                    >
                      <feather-icon
                        v-b-tooltip.hover.bottom="'Vider le champs!'"
                        icon="XCircleIcon"
                        class="cursor-pointer"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </div>
              <!-- email -->
              <div class="d-flex flex-row-reverse nopadding">
                <b-form-group
                  class="col pr-0 pl-0"
                  label-cols-xl="3"
                  label="Rechercher par l'email"
                >
                  <b-input-group>
                    <b-form-input
                      v-model="filterBy.email"
                      type="text"
                      class="form-control"
                      placeholder="Entrer l'email de l'utilisateur. Exemple: agent@gmail.com"
                      @keyup.enter="search()"
                    />
                    <b-input-group-append
                      is-text
                      @click="
                        () => {
                          filterBy.email = null;
                        }
                      "
                    >
                      <feather-icon
                        v-b-tooltip.hover.bottom="'Vider le champs!'"
                        icon="XCircleIcon"
                        class="cursor-pointer"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </div>
              <b-row>
                <b-col cols="12">
                  <div class="d-flex flex-column">
                    <b-form-checkbox
                      checked="true"
                      name="check-button"
                      switch
                      inline
                      v-model="filterStyleAnd"
                    >
                      {{
                        filterStyleAnd
                          ? 'Filtrer avec un "ET" logique'
                          : 'Filtrer avec un "OU" logique'
                      }}
                    </b-form-checkbox>
                    <p class="mt-1">
                      <feather-icon
                        icon="AlertTriangleIcon"
                        class="text-warning mr-1"
                      />
                      <small>{{
                        filterStyleAnd
                          ? 'Filtrer avec un "ET" logique ce que cela signifie que si vous choisissez plusieurs critères avec l\'option "ET", cela signifie que tous les critères doivent être vrais en même temps pour qu\'un résultat s\'affiche'
                          : 'Filtrer avec un "OU" logique ce que cela signifie si vous choisissez plusieurs critères avec l\'option "OU", cela signifie que si l\'un des critères est vrai, les résultats seront affichés.'
                      }}</small>
                    </p>
                  </div>
                  <!-- button -->
                  <b-button
                    class="float-right"
                    variant="primary"
                    @click="search()"
                  >
                    <feather-icon icon="SearchIcon" class="mr-25" />
                    <span>Rechercher</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <div class="row">
            <!-- down all -->
            <div class="col-lg-3 col-md-6 mt-1">
              <b-button
                class="float-right"
                variant="primary"
                block
                @click="uploadData('allData')"
              >
                <feather-icon icon="DownloadCloudIcon" class="mr-25" />
                <span>Télécharger toutes les données du formulaire</span>
                <b-spinner v-if="loadingExport" small class="ml-1" />
              </b-button>
            </div>
            <div class="col-lg-3 col-md-1" />
            <!-- down selection -->
            <div class="col-lg-3 col-md-1" />
            <div
              v-if="tableData && tableData.length > 0"
              class="col-lg-3 col-md-4 mt-1"
            >
              <b-button
                class="float-right"
                variant="primary"
                block
                @click="uploadData('filteredData')"
              >
                <feather-icon icon="DownloadCloudIcon" class="mr-25" />
                <span>Télécharger les données filtrées</span>
                <b-spinner v-if="loadingExport" small class="ml-1" />
              </b-button>
            </div>
          </div>
          <hr class="my-2" />
        </b-card>
      </b-card>
    </div>

    <!-- table -->
    <b-table
      responsive
      :items="tableData"
      :fields="tableColumns"
      show-empty
      empty-text="Aucun resultats"
      :busy="isLoadingTableData"
      hover
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle" />
        </div>
      </template>
      <template #cell(id)="data">
        <strong>{{
          (paginationData.perPage * (paginationData.currentPage - 1) || 0) +
          data.index +
          1
        }}</strong>
      </template>
      <template #cell(ref)="data">
        <b-avatar variant="dark"> <feather-icon icon="ImageIcon" /> </b-avatar
        >{{ "  " }}
        {{ data.item.ref }}
      </template>

      <template #cell(forms)="data">
        {{ (data && data.item.forms && data.item.forms.length) || 0 }}
      </template>
      <template #cell(actions)="data">
        <div class="d-flex flex-row">
          <b-button
            v-if="true"
            variant="primary"
            :to="{
              name: 'users-details',
              params: { userId: data.item._id },
            }"
          >
            <feather-icon icon="EyeIcon" />
            <span class="text-nowrap"> Détails</span>
          </b-button>
        </div>
      </template>
    </b-table>

    <div>
      <PaginationComponent
        :table-data="tableData"
        :per-page="paginationData.perPage"
        :total-rows="paginationData.total"
        @change="onPaginationChange"
      />
    </div>
    <!-- pagination -->
    <!-- <div
      v-if="tableData && tableData.length > 1"
      class="d-flex justify-content-between mt-1"
    >
      <div class="d-flex flex-row">
        <span class="my-auto mr-1">Afficher </span>
        <div>
          <b-form-select
            v-model="paginationData.perPage"
            :options="optionsPerPage"
            size="sm"
            class="form-control col"
          />
        </div>
        <span class="my-auto ml-1">lignes </span>
      </div>

      <div>
        <b-pagination
          v-model="paginationData.currentPage"
          :total-rows="paginationData.total"
          :per-page="paginationData.perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>

      <div class="my-auto">
        <div class="d-flex flex-row">
          <span
            class="my-auto"
          >Affichage de {{ paginationData.metaData.from }} à
            {{ paginationData.metaData.to }} sur {{ paginationData.total }}
          </span>
        </div>
      </div>
    </div> -->

    <!-- <div class="d-none d-sm-block">
      <div
        v-if="tableData && tableData.length > 1"
        class="mt-1 d-flex flex-wrap justify-content-between"
      >
        <div>
          <div class="d-flex">
            <span class="my-auto mr-1">Afficher </span>
            <div>
              <b-form-select
                v-model="paginationData.perPage"
                :options="optionsPerPage"
                size="sm"
                class="form-control col"
              />
            </div>
            <span class="my-auto ml-1">lignes </span>
          </div>
        </div>

        <div>
          <b-pagination
            v-model="paginationData.currentPage"
            :total-rows="paginationData.total"
            :per-page="paginationData.perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>

        <div class="">
          <span class="my-auto">Affichage de {{ paginationData.metaData.from }} à {{ paginationData.metaData.to }} sur {{ paginationData.total }} </span>
        </div>
      </div>
    </div> -->

    <!-- <div class="d-block d-sm-none">
      <div
        v-if="tableData && tableData.length > 1"
        class="mt-1"
      >
        <div class="ml-5">
          <div class="d-flex">
            <span class="my-auto mr-1">Afficher </span>
            <div>
              <b-form-select
                v-model="paginationData.perPage"
                :options="optionsPerPage"
                size="sm"
                class="form-control col"
              />
            </div>
            <span class="my-auto ml-1">lignes </span>
          </div>
        </div>

        <div class="mt-1 ml-5">
          <b-pagination
            v-model="paginationData.currentPage"
            :total-rows="paginationData.total"
            :per-page="paginationData.perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>

        <div class="mt-2 ml-5">
          <span class="my-auto">Affichage de {{ paginationData.metaData.from }} à {{ paginationData.metaData.to }} sur {{ paginationData.total }} </span>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import {
  BPagination,
  BAvatar,
  BButton,
  BTable,
  BSpinner,
  BFormSelect,
  BCardText,
  BCard,
  BCardBody,
  BCardHeader,
  BRow,
  BCol,
  BFormInput,
  BFormCheckbox,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  VBTooltip,
  BDropdownDivider,
  BCardTitle,
} from "bootstrap-vue";
import { mapGetters, mapActions } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ButtonArchiverUtilisateur from "@/views/apps/utilisateurs/administration/component/ButtonArchiverUtilisateur.vue";
import DateRangeWidget from "@/components/DateRangeWidget.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import PaginationComponent from "@/components/PaginationComponent.vue";

// store modules and vuex utilities
import etablissementsStroreModule from "@/store/etablissements";
import paramsStoreModule from "@/store/params";
import rolesStoreModule from "@/store/roles";
import usersStoreModule from "@/store/users";
import agentGouvenementStoreModule from "@/store/agent-gouvenement";

import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

export default {
  // name: 'UsersListComponent',
  setup() {
    const requiredStoreModules = [
      { path: "etablissements", module: etablissementsStroreModule },
      { path: "params", module: paramsStoreModule },
      { path: "users", module: usersStoreModule },
      { path: "roles", module: rolesStoreModule },
      { path: "agentGouvenement", module: agentGouvenementStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules };
  },

  components: {
    BFormSelect,
    BAvatar,
    BPagination,
    BButton,
    BTable,
    BSpinner,
    BCardText,
    BCard,
    BCardBody,
    BCardHeader,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    VBTooltip,
    BDropdownDivider,
    BCardTitle,
    vSelect,

    ButtonArchiverUtilisateur,
    DateRangeWidget,
    PaginationComponent,
  },

  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },

  props: { etablissementId: null },

  data() {
    return {
      form: {
        role: "",
      },
      confirmeArchiveAgentModalRef: "confirmeArchiveAgentModal",
      filters: { options: {} },
      rows: 100,
      tableData: null,
      tableDataEtablissement: [],
      roles: [],
      filteredTableData: [],
      paginationData: {
        currentPage: 1,
        total: 0,
        perPage: 20,
        metaData: { from: 0, to: 0 },
      },
      optionsPerPage: [10, 15, 30, 100, 200],
      tableColumns: [
        {
          key: "id",
          label: "#",
          sortable: true,
        },
        {
          key: "lastname",
          label: "Nom",
          sortable: true,
        },
        {
          key: "firstname",
          label: "Prénom",
          sortable: true,
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
        },
        {
          key: "role.nom",
          label: "Rôle",
          sortable: true,
        },

        "actions",
      ],
      isLoadingTableData: false,
      loadingExport: false,
      query: {
        options: {},
        filter: {},
      },
      filterBy: {
        periode: {},
        email: null,
        firstname: null,
        lastname: null,
        etablissement: null,
        role: null,
      },
      filterStyleAnd: false,
    };
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },

  computed: {
    ...mapGetters("params", {
      typesRoles: "getter_typesRoles",
    }),
    totalRows() {
      return (this.tableData && this.tableData.length) || 0;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        this.getListUsers();
        this.getListEtablissement();
        this.getListRole();
      },
    },
    perPage: {
      immediate: true,
      handler(val, old) {
        if (this.tableData && this.tableData[val - 1]) {
          this.filteredTableData = this.customCloneDeep(
            this.tableData.slice(0, val - 1)
          );
        }
      },
    },
    tableData: {
      immediate: true,
      handler(val, old) {
        if (this.tableData) {
          this.filteredTableData = this.customCloneDeep(this.tableData);
        }
      },
    },
    selectedCategorie: {
      immediate: true,
      async handler(val, old) {
        await this.action_fetchTypesRoles();
        this.getListUsers();
      },
    },
    "paginationData.currentPage": {
      handler(val, old) {
        this.getListUsers();
      },
    },
    "paginationData.perPage": {
      handler(val, old) {
        this.getListUsers();
      },
    },
  },
  methods: {
    ...mapActions("users", {
      action_fetchUsers: "fetchUsers",
      exportAdmins: "exportAdmins",
    }),
    ...mapActions("params", { action_fetchTypesRoles: "fetchTypesRoles" }),
    ...mapActions("etablissements", {
      getAgent: "getAgent",
      fetchEtablissements: "fetchEtablissements",
    }),
    ...mapActions("roles", { fetchRoles: "fetchRoles" }),
    ...mapActions("agentGouvenement", {
      createAgentGouvenement: "createAgentGouvenement",
      findAgentGouvenement: "findAgentGouvenement",
    }),
    uploadData(action) {
      this.loadingExport = true;

      if (action === "allData") {
        this.query.filter.type = [];
        this.query.filter.text = [];
      }
      if (action === "filteredData") {
        // haveFilter = true
        this.updateFilter();
      }
      this.exportAdmins({
        params: { ...this.query },
      })
        .then(async (res) => {
          if (res.status == 200) {
            this.loadingExport = false;
            try {
              const blob = res.data;
              const fileName = "liste_des_comptes.csv";
              const newBlob = new Blob([blob]);
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
              } else {
                // For other browsers: create a link pointing to the ObjectURL containing the blob.
                const objUrl = window.URL.createObjectURL(newBlob);
                const link = document.createElement("a");
                link.href = objUrl;
                link.download = fileName;
                link.click();
                // For Firefox it is necessary to delay revoking the ObjectURL.
                setTimeout(() => {
                  window.URL.revokeObjectURL(objUrl);
                }, 250);
              }
            } catch (error) {
              console.log("error: ", error);
            }
          }
        })
        .catch((err) => {
          this.loadingExport = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || "Une erreur est survenue",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    async getListUsers(somesParams) {
      this.isLoadingTableData = true;
      const userRoleReq = await this.action_fetchTypesRoles().catch((err) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message || "Une erreur est survenue",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
      if (!userRoleReq || !userRoleReq.data) {
        this.isCheckingAgentCount = false;
        this.canAddNewAgent = false;
        return;
      }
      const { resources } = userRoleReq.data;
      const userRole = (resources || []).find((e) => e.code === "agent_mtca");

      const userRoleId = userRole._id;

      if (
        somesParams &&
        somesParams.filter &&
        somesParams.filter.text &&
        somesParams.filter.text.length === 0 &&
        somesParams.filter.type.length === 0
      ) {
        somesParams.filter = {
          // text: [],
          // type: [],
        };
        somesParams.options = {
          isArchive: false,
        };
      }

      const params = {
        ...somesParams,
        page: this.paginationData.currentPage - 1,
        perPage: this.paginationData.perPage,
        filterStyleAnd: this.filterStyleAnd ? "$and" : "$or",
      };
      params.options = {
        isArchive: false,
      };
      console.log("params::: ⭕️", params);
      this.action_fetchUsers(params)
        .then(async (response) => {
          this.tableData = response.data.resources || [];
          this.paginationData.total = response.data.total || 0;
          this.isLoadingTableData = false;

          if (this.paginationData.metaData.from == 0) {
            this.paginationData.metaData.from += 1;
          } else {
            this.paginationData.metaData.from =
              this.paginationData.perPage * this.paginationData.currentPage -
              this.paginationData.perPage;
          }
          this.paginationData.metaData.to =
            this.paginationData.perPage * this.paginationData.currentPage -
            this.paginationData.perPage +
            this.tableData.length;
        })
        .catch((err) => {
          this.isLoadingTableData = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || "Une erreur est survenue",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    async getListEtablissement() {
      this.fetchEtablissements()
        .then(async (response) => {
          this.tableDataEtablissement = response.data.resources || [];
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || "Une erreur est survenue",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },

    async getListRole() {
      this.fetchRoles()
        .then(async (response) => {
          this.roles = response.data.resources || [];
          console.log("roles", this.roles);
          // Filtrer les objets de façon unique en fonction de l'attribut "code"
          const objetsUniques = Array.from(
            new Set(this.roles.map((a) => a.code))
          ).map((code) => {
            return this.roles.find((a) => a.code === code);
          });
          console.log("objetsUniques::: ", objetsUniques);
          this.roles = objetsUniques;
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || "Une erreur est survenue",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },

    search() {
      if (
        !this.filterBy.periode &&
        !this.filterBy.email &&
        !this.filterBy.firstname &&
        !this.filterBy.lastname &&
        !this.filterBy.etablissement &&
        !this.filterBy.role
      ) {
        (this.query = {
          options: {},
          filter: {},
        }),
          this.getListUsers({ ...this.query });
      }
      this.updateFilter();
      this.getListUsers({ ...this.query });
    },
    updateFilter() {
      this.query.filter = {
        text: [],
        type: [],
      };
      if (this.filterBy.periode) {
        const { from, to } = this.filterBy.periode;
        if (from) {
          this.query.filter.type.push("createdAt");
          if (to) {
            this.query.filter.text.push(`${from},${to}`);
          } else {
            this.query.filter.text.push(`${from},${from}`);
          }
        }
      }
      if (this.filterBy.email && this.filterBy.email != "") {
        this.query.filter.type.push("email");
        this.query.filter.text.push(this.filterBy.email);
      }
      if (this.filterBy.firstname && this.filterBy.firstname != "") {
        this.query.filter.type.push("firstname");
        this.query.filter.text.push(this.filterBy.firstname);
      }
      if (this.filterBy.lastname && this.filterBy.lastname != "") {
        this.query.filter.type.push("lastname");
        this.query.filter.text.push(this.filterBy.lastname);
      }
      if (this.filterBy.etablissement && this.filterBy.etablissement != "") {
        this.query.filter.type.push("etablissement");
        this.query.filter.text.push(this.filterBy.etablissement);
      }
      if (this.filterBy.role && this.filterBy.role != "") {
        this.query.filter.type.push("role");
        this.query.filter.text.push(this.filterBy.role);
      }
    },
    onPaginationChange(val) {
      this.paginationData.currentPage = val.currentPage;
      this.paginationData.perPage = val.perPage;
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.link-wrapper {
  display: flex;
  height: 40px;
  align-items: center;
  gap: 10px;
  a {
    text-transform: uppercase;
  }
}
.footer,
.footer .per-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.v-select {
  width: 100%;
}
</style>
